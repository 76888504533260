import { Fragment } from 'react';

import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import EventsTemplate from 'templates/events';

import makeMockZoomWebinar from 'components/content-blocks/webinar-registration/util/make-mock-zoom-webinar';
import mockACFWebinarPageTemplate from 'components/content-blocks/webinar-registration/util/mock-acf-webinar-page-template';

const WebinarTestPageOneTimeOccurred = () => {

  const defaultCopy = useStaticQuery(graphql`{
    wordpressAcfOptions {
      options {
        ...DefaultWebinarCopy
      }
    }
  }`);

  const getMockWebinar = () => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 5);
    return makeMockZoomWebinar('Test Webinar Occurred Without Recording', startDate, null, null);
  };

  return (
    <Fragment>
      <EventsTemplate
        mockRegistration={ true }
        pageContext={ {
          acf: {
            webinar_page_template: mockACFWebinarPageTemplate,
          },
          defaultTextCopy: defaultCopy.wordpressAcfOptions.options.webinar_registration_default_copy,
          upcomingOneTimeWebinars: [],
          zoomWebinar: getMockWebinar(),
        } }
      />
      <Helmet>
        <meta name="robots" content="noimageindex, nofollow, nosnippet" />
      </Helmet>
    </Fragment>
  );
};

export default WebinarTestPageOneTimeOccurred;
